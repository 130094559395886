.Rate-section {
    color: #D7C6AE !important;
}

.rate-contaner{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* .rate-us {
    display: flex !important;
    justify-content: center !important;
    padding-top: 20px;
} */

.rating {
    display: flex !important;
    justify-content: center !important;
}

.react-stars-wrapper-08448307004465747 {
    display: flex !important;
    justify-content: center !important;
}

/* Input Field for Name */
.client-name-input {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 1rem; /* Space between the name input and the comment textarea */
    background-color: #D7C6AE; /* Light cream background */
    border: 1px solid #304121; /* Dark green border for contrast */
    border-radius: 5px; /* Slightly rounded corners for a modern look */
    font-size: 1rem; /* Comfortable reading size */
    color: #304121; /* Dark green text for readability */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inset shadow for depth */
  }
  
  .client-name-input::placeholder {
    color: #304121; /* Dark green placeholder text */
    opacity: 0.7; /* Slightly transparent for a placeholder effect */
  }
  
  /* Textarea for Comment */
  .comment-box {
    width: 100%;
    padding: 10px 15px;
    background-color: #D7C6AE; /* Light cream background */
    border: 1px solid #304121; /* Dark green border for contrast */
    border-radius: 5px; /* Slightly rounded corners for a modern look */
    font-size: 1rem; /* Comfortable reading size */
    color: #304121; /* Dark green text for readability */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inset shadow for depth */
    resize: vertical; /* Allow vertical resizing only */
    height: 100px; /* Initial height */
  }
  
  .comment-box::placeholder {
    color: #304121; /* Dark green placeholder text */
    opacity: 0.7; /* Slightly transparent for a placeholder effect */
  }
  
  .submit-btn-container {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    margin-top: 1rem; /* Adds space above the button */
  }
  
  .submit-btn {
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: #304121; /* Dark green background */
    color: #D7C6AE; /* Light cream text */
    border: none; /* Removes the border */
    border-radius: 5px; /* Slightly rounded corners */
    font-size: 1rem; /* Comfortable reading size */
    cursor: pointer; /* Changes cursor to pointer on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .submit-btn:hover {
    background-color: #D7C6AE; /* Light cream background on hover */
    color: #304121; /* Dark green text on hover */
  }
  