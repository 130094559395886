.product-detail-container {
  background-image: url('../images/back4.jpg') !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    color: white;
    padding: 20px;
    min-height: 100vh;

  }
  
  .product-detail-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .product-detail-info {
    text-align: center;
  }
  
  .product-detail-title {
    font-size: 2rem; /* Adjust size as needed */
  }
  
  .product-detail-price {
    font-size: 1.5rem; /* Adjust size as needed */
    color: #4CAF50; /* Adjust color as needed */
  }
  
  .product-detail-description {
    margin: 10px 0;
  }
  
  .product-detail-description-ar {
    font-style: italic;
  }
  
  .product-detail-back {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333; /* Dark background for the button */
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }

  .product-detail-info p {
    padding: 5px;
    margin: 0;
  }
  
  /* Additional media queries and styles for responsiveness */
  