/* StarDisplay.css */
.star-rating {
    display: inline-flex;
    align-items: center;
    font-size: 1.5rem; /* Adjust the size of the stars */
    color: #ffcc00; /* Star color */
  }
  
  .star-rating .star {
    margin-right: 5px; /* Space between stars */
    cursor: pointer;
  }
  
  /* Add more styles here if needed */
  
  .rating-number {
    display: block;
  }