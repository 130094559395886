
  .navbar-nav {
    width: 100%;
    text-align: left; /* Align the text to the left */
    padding-left: 10px !important;
  }
  
  .nav-link {
    padding: 10px 20px; /* Add padding to increase touch area */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional separator */
    font-size: 34px !important;
    color: #D7C6AE !important;
    border-bottom: 1px solid !important;
  }
  .nav-link:hover {
    background-color: #D7C6AE;
    color: #304121 !important;
  }
  
  
  .navbar-nav {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  

  .navbar-custom {
    background-color: #84764F; /* Adjust to match your image background */
    border-bottom: 1px solid #444; /* Optional: if there's a border in the image */
  }
  
  .navbar-brand {
    font-family: 'Your font-family', sans-serif; /* Adjust to your logo font */
    font-size: 1.5rem; /* Adjust to match the size in the image */
    color: #D7C6AE;
    padding-left: 10px;
  }
  
  /* Hamburger icon color */
  .navbar-toggler {
    border-color: rgba(0, 0, 0, 0); /* Hide border */
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    /* Adjust the stroke color to change the color of the hamburger lines */
  }
  
  .navbar-nav {
    width: auto; /* Reset to default */
    text-align: left; /* Align the text to the left */
  }
  
  .nav-link {
    color: #D7C6AE;
    padding: 10px 20px; /* Add padding to increase touch area */
    font-size: 1rem; /* Adjust font size for larger screens */
    border-bottom: none; /* Remove border bottom */
  }
  
  .nav-link:hover {
    background-color: #D7C6AE;
    color: #304121;
  }
  
  /* Reset collapse styles for larger screens */
  .navbar-collapse {
    position: relative; /* Reset to default */
    top: auto; /* Reset to default */
    right: auto; /* Reset to default */
    height: auto; /* Reset to default */
    width: auto; /* Reset to default */
    background-color: transparent; /* Reset to default */
    transform: none; /* Reset to default */
  }
  
  /* Larger screen layout */
  @media (min-width: 992px) {
    .navbar-nav {
      display: flex; /* Align nav items in a row */
      flex-direction: row;
      justify-content: flex-end; /* Align to the right side */
    }
  
    .nav-link {
      font-size: 1.2rem; /* Slightly larger font size for larger screens */
      padding: 0.5rem 1rem; /* Adjust padding for larger screens */
    }
  
    /* Show the navbar-collapse by default */
    .navbar-collapse {
      transform: none !important;
    }
  }
  
  /* Smaller screen layout */
  @media (max-width: 991px) {
    .navbar-brand {
      font-size: 1.25rem; /* Smaller font size for mobile screens */
    }
  
    .navbar-nav {
      padding-left: 0; /* Remove padding for mobile layout */
      flex-direction: column; /* Stack nav items vertically */
      align-items: center; /* Center align nav items */
    }
  
    .nav-link {
      font-size: 1.5rem; /* Larger font size for mobile screens */
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional separator */
    }
  
    /* Full-height navbar-collapse */
    .navbar-collapse {
      position: fixed; /* Fixed to the screen */
      top: 0;
      left: 0;
      height: 100%; /* Full height */
      width: 100%;
      background-color: rgb(132, 118, 79, 0.8); /* Match your navbar color */
      z-index: 9999;
      transition: transform 0.3s ease-out;
      transform: translateX(100%);
    }
  
    .navbar-collapse.collapsing {
      transition: none;
      transform: translateX(100%);
    }
  
    .navbar-collapse.show {
      transform: translateX(0);
    }
  }
  
  .navbar-brand {
    padding: 10px;
    color: white !important;
  }

  .navbar-dark {
    padding: 10px !important;
  }

  /* Reset collapse styles for larger screens */
.navbar-collapse {
  position: fixed; /* Keep it fixed */
  top: 0;
  left: 0;
  height: 100%; /* Full screen height */
  width: 100%; /* Full screen width */
  background-color: rgba(132, 118, 79, 0.9); /* Slightly transparent background */
  z-index: 9999; /* Make sure it's on top */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth transition for transform and opacity */
  transform: translateX(-100%); /* Start off-screen to the left */
  opacity: 0; /* Start fully transparent */
}

.navbar-collapse.collapsing {
  transition: none; /* Disable transition during collapse */
  opacity: 0.5; /* Half transparent when collapsing */
}

.navbar-collapse.show {
  transform: translateX(0); /* Move to fully visible on-screen */
  opacity: 1; /* Fully opaque when shown */
}

/* Hamburger icon animation */
.navbar-toggler.collapsed .navbar-toggler-icon {
  transition: transform 0.3s ease;
  transform: rotate(0deg); /* Normal state */
}

.navbar-toggler .navbar-toggler-icon {
  transition: transform 0.3s ease;
  transform: rotate(90deg); /* Rotated state */
}




@keyframes slideIn {
  0% {
    transform: scale(0.95) rotate(-5deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

.navbar-collapse {
  display: block; /* Override Bootstrap's default flex display */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(132, 118, 79, 0.95); /* Adjust for your theme */
  transform: scale(0.95) rotate(-5deg); /* Start scaled and rotated */
  opacity: 0; /* Start invisible */
  transition: visibility 0s, opacity 0.5s linear;
  visibility: hidden; /* Hide by default */
}

.navbar-collapse.show {
  animation: slideIn 0.5s forwards; /* Animate in */
  visibility: visible; /* Ensure it's visible when expanded */
}

.nav-link {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.navbar-collapse.show .nav-link {
  opacity: 1;
  transform: translateY(0);
}

/* Example delays for each link, adjust as needed */
.navbar-collapse.show .nav-link:nth-child(1) { transition-delay: 0.2s; }
.navbar-collapse.show .nav-link:nth-child(2) { transition-delay: 0.4s; }
.navbar-collapse.show .nav-link:nth-child(3) { transition-delay: 0.6s; }
.navbar-collapse.show .nav-link:nth-child(4) { transition-delay: 0.8s; }


.navbar-brand {
  font-weight: 700; /* Bold font for the brand name */
  transition: transform 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.navbar-brand:hover {
  transform: scale(1.05); /* Slightly enlarge on hover for emphasis */
}

.navbar-toggler {
  border: none; /* Remove default border */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23F3EDE0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  /* Custom color for the toggler icon */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar-collapse {
  transform: translateY(-100%);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 0;
}

.navbar-collapse.show {
  transform: translateY(0);
  opacity: 1;
}

.nav-link {
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
}

.navbar-collapse.show .nav-link {
  opacity: 1;
}

.nav-link:nth-child(1) { animation-delay: 0.2s; }
.nav-link:nth-child(2) { animation-delay: 0.4s; }
.nav-link:nth-child(3) { animation-delay: 0.6s; }
.nav-link:nth-child(4) { animation-delay: 0.8s; }

.nav-link {
  margin: 5px 0; /* Add some vertical spacing */
  transition: color 0.3s ease-in-out; /* Smooth color transition */
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none; /* Remove underline effect */
}

@media (max-width: 768px) {
  .navbar-brand {
    font-size: 18px; /* Smaller font size for brand name on mobile */
  }

  .nav-link {
    font-size: 16px; /* Adjust link size for better mobile readability */
  }
}
