.product-display-container {
    background-image: url('../images/back4.jpg') !important;
    z-index: 100000;
    background-size: cover;
    min-height: 100vh;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This makes sure the container takes the full viewport height */
}

.logo-spinner {
    animation: spin 2s linear infinite;
    width: 130px; /* Set the logo width */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
