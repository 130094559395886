.hero-section-v2 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }
  
  .hero-text-v2 h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  .specialties-section h2,
  .reviews-section h2 {
    margin-bottom: 1.5rem;
  }
  
  .specialty-card {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  .about-section-v2 {
    background-color: #f4f4f4;
    padding: 50px 0;
  }
  
  /* .testimonials-section {
    color: #D7C6AE;
  } */

  .about-section {
    color: #D7C6AE;
  }
  
  h3 {
    color: #D7C6AE !important;
  }

  .homepage {
    min-height: 100vh;
    background-image: url('../images/back4.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .homepage .hero-section {
    text-align: center;
    padding: 100px 20px 25px;
    color: white;
  }
  
  .featured-section, .testimonials-section, .about-section {
    margin-bottom: 50px;
  }
  
  .featured-dishes .dish {
    margin-bottom: 20px;
    /* Add more styles for your dishes */
  }
  
  /* Add more styles as needed */
  
  .showthemenu {
    background: #84764F;
    /* background-image: url('../images/background.jpg'); */
    height: 58px;
    width: 288px;
    border: 1px solid #b5a286;
    border-radius: 5px;
  }

  .showthemenu h4 {
    color: white;
    font-size: 30px;
    padding-top: 6px;
    font-family: serif;
  }

.addrate {
  /* background-image: url('../images/background.jpg'); */
  display: block;
  width: 130px;
  height: 38px;
  background: #84764F;
  border: 1px solid #b5a286;
  border-radius: 5px;
}

.addrate h4 {
  color: white;
  font-size: 19px;
  padding-top: 3px
}

.Rate-section {
  padding: 0;
}

.rating-section {
display: flex;
flex-direction: column;
align-items: center;
}

.my-5 {
  margin-top: 0 !important;
}

.language-toggle {
  position: fixed; /* Fixed position to keep the button visible */
  bottom: 50px; /* Position from the bottom */
  right: 20px; /* Position from the right */
  padding: 10px 20px; /* Padding for size */
  background-color: #007bff; /* Primary color, you can adjust this */
  color: #ffffff; /* Text color */
  border: none; /* Remove border */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer; /* Cursor pointer to indicate it's clickable */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  font-size: 16px; /* Text size */
  font-weight: bold; /* Bold text */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
}

.language-toggle:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 4px 6px rgba(0,0,0,0.2); /* Enhanced shadow on hover */
}

.language-toggle:focus {
  outline: none; /* Remove outline to keep the design clean */
}

/* Comments Section Header */
.client-comments-header {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
}

/* Comments Section Header */
.client-comments-header {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.8rem;
  color: white;
  font-weight: 600;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #D7C6AE;
  border-radius: 5px;
  background: #84764F;
  width: 100%;
  height: 50px; /* Underline effect with main color */
}

/* Container for Individual Comments */
.client-comments {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: #D7C6AE; /* Light cream background for contrast */
  border-radius: 10px; /* Soft rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Deeper shadow for prominence */
}

.client-comments p {
  font-size: 1.1rem; /* Comfortable reading size */
  color: #304121; /* Text color that complements the background */
  border-bottom: 1px dashed #304121; /* Dashed separator for a subtle distinction */
  padding-bottom: 0.75rem; /* Generous space below each comment */
  margin-bottom: 0.75rem; /* Space between comments */
  font-style: italic; /* Stylistic choice to differentiate comments */
}

.client-comments p:last-child {
  border-bottom: none; /* Remove border from the last comment */
}

.comment-entry {
  border-bottom: 1px dashed #304121; /* Separator between comment entries */
  padding-bottom: 0.75rem; /* Space below each comment entry */
  margin-bottom: 0.75rem; /* Space between comment entries */
}

.comment-entry:last-child {
  border-bottom: none; /* Remove border from the last comment entry */
}

.comment-name {
  font-size: 1.1rem; /* Size for the name */
  color: #304121; /* Dark green for contrast */
  font-weight: bold; /* Make the name stand out */
  margin-bottom: 0.25rem; /* Small space between the name and the comment text */
}

.comment-text {
  font-size: 1rem; /* Slightly smaller font size for the comment text */
  color: #304121; /* Keeping the color consistent with the name */
  font-style: italic; /* Stylistic choice for the comment text */
}

.client-comment {
  margin-bottom: 1.5rem; /* Space between comments */
}

.client-comment p {
  margin: 0.5rem 0; /* Space around the name and comment */
  padding-top: 5px;
}

.client-comment strong {
  display: block; /* Ensure the name is on its own line */
  color: #304121; /* Your main dark green color */
  margin-bottom: 0.25rem; /* Small space above the star rating */
}

.see-more-btn {
  display: block; /* Makes the button a block element to allow margin auto to work */
  margin: 20px auto; /* Centers the button horizontally and adds space above and below */
  padding: 10px 20px;
  background-color: #304121; /* Your dark green color */
  color: #D7C6AE; /* Your light cream color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.see-more-btn:hover {
  background-color: #D7C6AE;
  color: #304121;
}
