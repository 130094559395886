* {
  overflow-x: hidden;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}


body {
  /* background-image: url('./images/Cover1.jpg');  */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.App {
  overflow-x: hidden !important;
  max-width: 50vh;
  height: auto;
  color: white;
  font-family: 'Arial', sans-serif;
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}
